<template>
  <div>
    <div class="flix-row">
      <div class="flix-col-md-12" >
        <div class="flix-list-group">
          <div v-for="(i, index) in events" :key="index">
            <div class="flix-list-group-item flix-active">
              <h2 class="flix-html-h4">{{ $tc('message.eventTitle', 1) }} {{ index + 1 }} / {{ events.length }}</h2>
            </div>
            <div class="flix-list-group-item">
              <eventTime :title="data.title" :data="i" :callback="function(r, a) { setData(r, a) }" :number="index + 1" />
            </div>
            <div class="flix-list-group-item" v-if="data.form != 'privateEvent'">
              {{ $tc('message.eventTitle', 1) }} {{ index + 1 }} / {{ events.length }}&nbsp;&nbsp;
              <a href="#" class="flix-btn flix-btn-xs flix-btn-info" @click.prevent="function() { addData(index) }"><flixIcon id="plus" /></a>
              <a href="#" class="flix-btn flix-btn-xs flix-btn-danger" v-if="index" @click.prevent="function() { removeData(index) }"><flixIcon id="minus" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="flixFadeIn">
      <div class="flix-form-group">
        <a v-if="checkDate()" href="#" class="flix-btn flix-btn-success" @click.prevent="saveData()">{{ $t('message.save', { name: '' }) }}</a>
        <div v-else class="flix-text-danger"><flixIcon :id="'warning-sign'" /> {{ $t('message.check', { name: $t('message.timeRange') }) }}</div>
      </div>
    </transition>

  </div>
</template>
<script>
export default {
  components: {
    eventTime () { return import('@/components/assistent/components/eventpicker/eventTime') }
  },
  props: {
    data: Object,
    callback: Function
  },
  data () {
    return {
      events: this.getEvents()
    }
  },
  methods: {
    getEvents () {
      if (typeof this.data.events === 'object') {
        return JSON.parse(JSON.stringify(this.data.events))
      }
      return false
    },
    checkDate () {
      return true
    },
    setData (r, number) {
      this.events[number - 1] = r
    },
    addData (int) {
      this.events.splice(int, 0, this.events[int])
    },
    removeData (int) {
      this.events.splice(int, 1)
      this.events = Object.values(this.events)
    },
    saveData () {
      this.data.events = this.events
      this.callback({ events: this.events })
    }
  },
  mounted () {
    if (!this.events.length) {
      this.events = [{}]
    }
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
